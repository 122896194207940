import { Directive, ElementRef, HostListener, Inject, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WINDOW } from '../tokens';

@Directive({
  selector: '[appInvalidControlScroll]',
  standalone: true,
})
@UntilDestroy()
export class InvalidControlScrollDirective {
  @Input()
  public offset = 100;

  constructor(
    private readonly _elementRef: ElementRef,
    private readonly _formGroupDirective: FormGroupDirective,
    @Inject(WINDOW)
    private readonly _window: Window,
  ) {}

  private _getTopOffset(controlEl: HTMLElement): number {
    return controlEl?.getBoundingClientRect()?.top + window.scrollY - this.offset;
  }

  @HostListener('ngSubmit')
  public onSubmit(): void {
    if (this._formGroupDirective.control.invalid) {
      this._scrollToFirstInvalidControl();
    }
  }

  private _scrollToFirstInvalidControl(): void {
    const firstInvalidControl: HTMLElement = this._elementRef.nativeElement.querySelector('.ng-invalid');

    this._window.scroll({
      top: this._getTopOffset(firstInvalidControl),
      left: 0,
      behavior: 'smooth',
    });

    fromEvent(this._window, 'scroll')
      .pipe(debounceTime(100), take(1))
      .subscribe(() => firstInvalidControl?.focus());
  }
}
