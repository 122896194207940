import { DestroyRef, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { fromEvent, Observable, of, throwError } from 'rxjs';
import { setFormErrors } from '../utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FormErrorHandlerService {
  public readonly offset = 100;

  constructor(
    private readonly _toastrService: ToastrService,
    private readonly _destroyRef: DestroyRef,
  ) {}

  public showErrorToastIfExists(error: any): void {
    if (error === 'timeout') {
      this._toastrService.warning('Sieć internetowa, z której korzystasz jest zbyt słaba.', 'Koniec czasu');
    }
    this._displayErrorMessageIfExists(error);
  }

  public catchFormError(error: any, formGroup: UntypedFormGroup | FormGroup): Observable<any> {
    this.showErrorToastIfExists(error);
    if (error?.error?.code === 400) {
      setFormErrors(error?.error?.errors?.children, formGroup);
      formGroup.updateValueAndValidity();
      this._scrollToFirstInvalidControl();
      return of(null);
    }
    return throwError(() => error);
  }

  private _displayErrorMessageIfExists(error: any): void {
    if (
      error?.error?.errors?.errors &&
      Array.isArray(error?.error?.errors?.errors) &&
      error?.error?.errors?.errors.length
    ) {
      this._toastrService.error(error?.error?.errors?.errors[0]);
    }
  }

  private _getTopOffset(controlEl: Element | null): number {
    if (!controlEl) {
      return 0;
    }
    return controlEl.getBoundingClientRect().top + window.scrollY - this.offset;
  }

  private _scrollToFirstInvalidControl(): void {
    const firstInvalidControl = document.querySelector('.ng-invalid');

    window.scroll({
      top: this._getTopOffset(firstInvalidControl),
      left: 0,
      behavior: 'smooth',
    });

    fromEvent(window, 'scroll')
      .pipe(takeUntilDestroyed(this._destroyRef), debounceTime(100), take(1))
      .subscribe(() => (firstInvalidControl as HTMLElement)?.focus());
  }
}
