import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IUser } from '../models/user';
import { SERVICES_API } from '@acd-loreal/data';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    @Inject(SERVICES_API)
    private readonly _servicesApi: string,
    private readonly _httpClient: HttpClient,
  ) {}

  /**
   * Logout user. Destroy session.
   */
  public logout(): Observable<void> {
    return this._httpClient.get<void>(`${this._servicesApi}/platform/logout`);
  }

  /**
   * Logout loreal user. Destroy session.
   */
  public logoutLorealClient(): Observable<void> {
    return this._httpClient.get<void>(`${this._servicesApi}/loreal-client/logout`);
  }

  /**
   * Get logged user details.
   */
  public getMe(): Observable<IUser> {
    if (environment.development) {
      return this._httpClient.get<IUser>(`${this._servicesApi}/platform/client/me`).pipe(
        map((user) => ({
          ...user,
          vichy_mega_brand: null,
        })),
      );
    }
    return this._httpClient.get<IUser>(`${this._servicesApi}/platform/client/me`);
  }

  /**
   * Get logged user loreal details.
   */
  public getMeLorealClient(): Observable<IUser> {
    return this._httpClient.get<IUser>(`${this._servicesApi}/loreal-client/client/me`);
  }

  /**
   * Get education login url
   */
  public loginToEducation(dto: {
    response_type: string;
    client_id: string;
    scope?: string;
    redirect_to?: string;
  }): Observable<string> {
    return this._httpClient.get<string>(`${this._servicesApi}/platform/client/education/login`, {
      params: dto,
      responseType: 'text' as 'json',
    });
  }

  /**
   * Get education login url
   */
  public loginToEducationByLorealClient(dto: {
    response_type: string;
    client_id: string;
    scope?: string;
    redirect_to?: string;
  }): Observable<string> {
    return this._httpClient.get<string>(`${this._servicesApi}/loreal-client/client/education/login`, {
      params: dto,
      responseType: 'text' as 'json',
    });
  }

  /**
   * Hide welcome video
   */
  public hideWelcomeVideo(): Observable<unknown> {
    return this._httpClient.post(`${this._servicesApi}/platform/client/hide-welcome-video`, {});
  }

  /**
   * Hide instructal video
   */
  public hideInstructalVideo(): Observable<unknown> {
    return this._httpClient.post(`${this._servicesApi}/platform/client/hide-instruction-video`, {});
  }
}
