export const environment = {
  production: true,
  test: false,
  development: false,
  api: 'https://www.dermopartner.pl/api',
  chat: 'https://www.dermopartner.pl/chat',
  upload: 'https://www.dermopartner.pl/api/uploads',
  gtm: 'GTM-TC5GM7JQ',
  recaptchaV3SiteKey: '6LevVBQjAAAAAMG9ATK9rTOqugkEz0vBTQ8ITuKm',
  client_id: '90735e79e8f4534dad3bc4dcb40ef77c',
  flowPlayerPlayerId: 'b661fd46-eeac-4f1d-9a2d-0b1a6bfac2fd',
  flowPlayerToken:
    'eyJraWQiOiJxV3FyRWFmQXV1UjUiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6NixcImlkXCI6XCJxV3FyRWFmQXV1UjVcIn0iLCJpc3MiOiJGbG93cGxheWVyIn0.Aup4eNhtBC-Ffn5EvFzoBdxFTCx14odRjkEtcss3ldCpo2JMbeD5Sv4P-IuMXRbmiA2a_TbJcZCqTZJKR4TPTQ',
  oneScriptDataDomainKey: 'bff6211e-0d6d-4e94-9924-4d430a2da26e',
  sciencePharmaLiveStreamId: 'fa832a0e-b18c-4534-b1ea-657a33879b0b',
  url: 'https://www.dermopartner.pl/',
  megabrandLiveStreamId: 'fa832a0e-b18c-4534-b1ea-657a33879b0b',
};
