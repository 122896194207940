import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { mergeMap, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RouteSlug } from '../models/route-slug.enum';
import { SERVICES_CUSTOMER_TOKEN } from '@acd-loreal/data';
import {
  AppState,
  AppStateClearUserData,
  AppStateGetUserData,
  AppStateLogout,
  AppStateSetJwtToken,
} from '../app.state';
import { isJwtTokenExpired } from '../helpers/is-jwt-token-expired';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private readonly _store: Store,
    @Inject(SERVICES_CUSTOMER_TOKEN)
    private readonly _servicesCustomerToken: () => string,
  ) {}

  public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const token = this._servicesCustomerToken();
    if (!token || isJwtTokenExpired(token)) {
      this._store.dispatch(new AppStateClearUserData());
      return this._goToLoginPage(state);
    }

    const user = this._store.selectSnapshot(AppState.user);

    if (!user) {
      return this._store.dispatch(new AppStateGetUserData()).pipe(
        catchError((err) => {
          if (err?.status === 403) {
            this._store
              .dispatch(new AppStateLogout())
              .pipe(mergeMap(() => this._store.dispatch(new AppStateSetJwtToken({ token: null }))));
          }
          this._goToLoginPage(state);
          return throwError(() => err);
        }),
      );
    }

    return of(true);
  }

  private _goToLoginPage(_: RouterStateSnapshot): Observable<boolean> {
    return of(false).pipe(
      tap(() => {
        this._store.dispatch(new Navigate([RouteSlug.AUTH, RouteSlug.LOGIN]));
      }),
    );
  }

  private _goToAccountPage(_: RouterStateSnapshot): Observable<boolean> {
    return of(false).pipe(
      tap(() => {
        this._store.dispatch(new Navigate(['/', RouteSlug.ACCOUNT]));
      }),
    );
  }
}
