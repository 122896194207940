import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RouteSlug } from '../../../../../../../../models';

@Component({
  selector: 'acd-scorm-popup',
  templateUrl: './scorm-popup.component.html',
  styleUrls: ['./scorm-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, RouterModule],
})
export class ScormPopupComponent {
  public readonly routeSlug = RouteSlug;
  private readonly _ngbActiveModal = inject(NgbActiveModal);

  public onClose(): void {
    this._ngbActiveModal.close();
  }
}
