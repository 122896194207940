import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@Directive({
  selector: '[appInputIcon]',
  standalone: true,
})
@UntilDestroy()
export class InputIconDirective implements OnInit {
  @Input({ alias: 'appInputIcon', required: true })
  public urlToIcon: string;

  @Input({ required: true })
  public iconAlt: string;

  @Input({ required: true })
  public width: string;

  @Input({ required: true })
  public height: string;

  constructor(
    private readonly _renderer2: Renderer2,
    private readonly _elementRef: ElementRef,
    @Inject(DOCUMENT)
    private readonly _document: Document,
  ) {}

  public ngOnInit(): void {
    this._renderer2.setStyle(this._elementRef.nativeElement, 'posiiton', 'relative');

    const input = this._elementRef.nativeElement.children[0];

    const img = this._renderer2.createElement('img');

    this._renderer2.setStyle(img, 'display', 'block');
    this._renderer2.setStyle(img, 'width', `${this.width}px`);
    this._renderer2.setStyle(img, 'height', `${Number(this.height)}px`);
    this._renderer2.setStyle(img, 'position', 'absolute');
    this._renderer2.setStyle(img, 'bottom', `${(50 - Number(this.height)) / 2}px`);
    this._renderer2.setStyle(img, 'left', '20px');
    this._renderer2.setStyle(img, 'zIndex', '100');
    this._renderer2.setAttribute(img, 'src', this.urlToIcon);
    this._renderer2.setAttribute(img, 'alt', this.iconAlt);

    this._renderer2.appendChild(this._elementRef.nativeElement, img);
    this._renderer2.setStyle(input, 'paddingLeft', '50px');
  }
}
