import { Inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  CLIENT_ROLE,
  IBenefitCategoriesDropdown,
  IBenefitDetails,
  IBenefitMarkDropdown,
  IBenefitsList,
  IBenefitTypeDropdown,
  ILorealUser,
  IUser,
  RouteSlug,
} from './models';
import { from, Observable, of, tap, throwError } from 'rxjs';
import { DictionariesService } from './services/dictionaries.service';
import { catchError, concatMap, delay, finalize, switchMap } from 'rxjs/operators';
import { IPharmacy } from './models/pharmacy/pharmacy';
import { UserService } from './services/user.service';
import { Navigate } from '@ngxs/router-plugin';
import { NgxPermissionsService } from 'ngx-permissions';
import { ClientRoleEnum, LanguageEnum, WINDOW } from '@acd-loreal/data';
import { environment } from '../environments/environment';
import { IPharmacyUserDataItem } from './models/pharmacy/pharmacy-user-data-item';
import { Decimal } from 'decimal.js';
import { IPharmacyDetails } from './models/pharmacy/pharmacy-details';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LANGUAGE_KEY } from './app.consts';
import { ScormPopupComponent } from './pages/core/pages/account/pages/webinars/components/scorm-popup/scorm-popup.component';
import { IChallengesList } from './pages/core/pages/account/pages/__challenges/models/challenges-list';

export function getDefaultLanguage(): LanguageEnum {
  const localStoreLanguage = localStorage.getItem(LANGUAGE_KEY)?.replace(/(['"])/g, '') as LanguageEnum;
  if (localStoreLanguage) {
    return localStoreLanguage;
  }

  const userLocaleLanguage = window.navigator.language.split(/[-_]/)[0];
  const availableLanguages = [
    LanguageEnum.PL.valueOf(),
    LanguageEnum.LT.valueOf(),
    LanguageEnum.LV.valueOf(),
    LanguageEnum.ET.valueOf(),
  ];
  if (availableLanguages.includes(userLocaleLanguage)) {
    return userLocaleLanguage as LanguageEnum;
  }

  return LanguageEnum.PL;
}

export class AppStateSetJwtToken {
  public static readonly type = '[AppState] set jwt token';

  constructor(public readonly payload: { token: string }) {}
}

export class AppStateLogout {
  public static readonly type = '[AppState] logout';
}

export class AppStateClearUserData {
  public static readonly type = '[AppState] clear user data';
}

export class AppStateGetUserData {
  public static readonly type = '[AppState] get user data';
}

export class AppStateSetUserData {
  public static readonly type = '[AppState] set user data';

  constructor(public readonly payload: { user: IUser }) {}
}

export class AppStateSetLanguage {
  public static readonly type = '[AppState] set language';

  constructor(public readonly payload: { language: LanguageEnum }) {}
}

export class AppStateFetchPharmaciesNetworks {
  public static readonly type = '[AppState] fetch pharmacies networks';
}

export class AppStateSearchPharmacies {
  public static readonly type = '[AppState] search pharmacies';

  constructor(public readonly payload: { term: string; country?: LanguageEnum }) {}
}

export class AppStateLoginToEducation {
  public static readonly type = '[AppState] login to education';

  constructor(public readonly redirect_to?: string) {}
}

export class AppStateGetListOfBenefitCategories {
  public static readonly type = '[AppState] get list of benefit categories';
}

export class AppStateGetListOfBenefitMarks {
  public static readonly type = '[AppState] get list of benefit marks';
}

export class AppStateGetListOfBenefitTypes {
  public static readonly type = '[AppState] get list of benefit types';
}

export class AppStateAddOrRemoveBenefitFromShoppingCart {
  public static readonly type = '[AppState] add reward or remove benefit from shopping cart';

  constructor(public readonly payload: { benefit: IBenefitDetails; quantity: number }) {}
}

export class AppStateSetBenefitQuantity {
  public static readonly type = '[AppState] set benefit quantity';

  constructor(public readonly payload: { benefit: IBenefitDetails; quantity: number }) {}
}

export class AppStateRemoveWholeBenefitFromShoppingCart {
  public static readonly type = '[AppState] remove whole benefit from shopping cart';

  constructor(public readonly payload: { benefit: IBenefitDetails }) {}
}

export class AppStateClearShoppingCart {
  public static readonly type = '[AppState] clear shopping cart';
}

export class AppStateFetchBenefitsForDashboard {
  public static readonly type = '[AppState] fetch benefits for dashboard';
}

export class AppStateFetchChallengesForDashboard {
  public static readonly type = '[AppState] fetch challenges for dashboard';

  constructor(public readonly payload: { selectedPharmacyUuid: string }) {}
}

export class AppStateHideVideo {
  public static readonly type = '[AppState] hide video';

  constructor(public videoName: 'welcome' | 'instructal') {}
}

export class AppStateOpenInitialPopups {
  public static readonly type = '[AppState] show initial popups';
}

export class AppStateSetAcdAddressAfterLoggingIn {
  public static readonly type = '[AppState] set acd address after logging in';

  constructor(public readonly payload: { addressAfterLoggingIn: string }) {}
}

export interface AppStateModel {
  loading: boolean;
  isCloseAskAboutOfferBtnVisible: boolean;
  cookiesAllowed: boolean | null;
  pharmaciesNetworks: { id: number; name: string }[] | null;
  pharmacies: IPharmacy[];
  token: string;
  user: IUser | ILorealUser | null;
  language: LanguageEnum;
  selectedPharmacyUuid: string;
  selectedPharmacy: IPharmacyDetails;
  benefitCategories: IBenefitCategoriesDropdown[] | null;
  benefitMarks: IBenefitMarkDropdown[] | null;
  benefitTypes: IBenefitTypeDropdown[] | null;
  benefitsShoppingCart: {
    [pharmacyUuid: string]: {
      [benefitSlug: string]: { quantity: number; benefit: IBenefitDetails; available: boolean };
    };
  };
  benefitsForDashboard: IBenefitsList[] | null;
  challengesForDashboard: IChallengesList[] | null;
  isLorealEmployee: boolean;
  addressAfterLoggingIn: string;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    loading: false,
    isCloseAskAboutOfferBtnVisible: true,
    cookiesAllowed: null,
    pharmaciesNetworks: null,
    pharmacies: [],
    token: null,
    user: null,
    language: getDefaultLanguage(),
    selectedPharmacyUuid: null,
    selectedPharmacy: null,
    benefitCategories: null,
    benefitMarks: null,
    benefitTypes: null,
    benefitsShoppingCart: {},
    benefitsForDashboard: null,
    challengesForDashboard: null,
    isLorealEmployee: null,
    addressAfterLoggingIn: '',
  },
})
@Injectable()
export class AppState {
  constructor(
    private readonly _dictionariesService: DictionariesService,
    private readonly _userService: UserService,
    private readonly _ngxPermissionsService: NgxPermissionsService,
    @Inject(WINDOW)
    private readonly _window: WindowProxy,
    private readonly _ngbModal: NgbModal,
  ) {}

  @Selector()
  public static loading({ loading }: AppStateModel): boolean {
    return loading;
  }

  @Selector()
  public static pharmaciesNetworks({ pharmaciesNetworks }: AppStateModel): { id: number; name: string }[] | null {
    return pharmaciesNetworks;
  }

  @Selector()
  public static pharmacies({ pharmacies }: AppStateModel): IPharmacy[] {
    return pharmacies;
  }

  @Selector()
  public static token({ token }: AppStateModel): string {
    return token;
  }

  @Selector()
  public static user({ user }: AppStateModel): IUser | ILorealUser | null {
    return user;
  }

  @Selector()
  public static language({ language }: AppStateModel): LanguageEnum {
    return language;
  }

  @Selector()
  public static selectedPharmacyUuid({ selectedPharmacyUuid }: AppStateModel): string {
    return selectedPharmacyUuid;
  }

  @Selector()
  public static selectedPharmacy({ selectedPharmacy }: AppStateModel): IPharmacyDetails {
    return selectedPharmacy;
  }

  @Selector()
  public static benefitCategories({ benefitCategories }: AppStateModel): IBenefitCategoriesDropdown[] | null {
    return benefitCategories;
  }

  @Selector()
  public static benefitMarks({ benefitMarks }: AppStateModel): IBenefitMarkDropdown[] | null {
    return benefitMarks;
  }

  @Selector()
  public static benefitTypes({ benefitTypes }: AppStateModel): IBenefitTypeDropdown[] | null {
    return benefitTypes;
  }

  @Selector()
  public static benefitsShoppingCart({ benefitsShoppingCart }: AppStateModel): {
    [pharmacyUuid: string]: {
      [benefitSlug: string]: { quantity: number; benefit: IBenefitDetails; available: boolean };
    };
  } {
    return benefitsShoppingCart;
  }

  @Selector()
  public static benefitsShoppingCartFullQuantity({
    benefitsShoppingCart,
    selectedPharmacyUuid,
  }: AppStateModel): number {
    if (!selectedPharmacyUuid || !benefitsShoppingCart || !benefitsShoppingCart[selectedPharmacyUuid]) {
      return 0;
    }
    return Object.keys(benefitsShoppingCart[selectedPharmacyUuid])?.reduce(
      (previousValue, currentValue) =>
        previousValue + benefitsShoppingCart[selectedPharmacyUuid][currentValue].quantity,
      0,
    );
  }

  @Selector()
  public static benefitsShoppingCartFullRequiredPoints({
    benefitsShoppingCart,
    selectedPharmacyUuid,
  }: AppStateModel): number {
    if (!selectedPharmacyUuid || !benefitsShoppingCart || !benefitsShoppingCart[selectedPharmacyUuid]) {
      return 0;
    }
    return Object.keys(benefitsShoppingCart[selectedPharmacyUuid]).reduce(
      (previousValue, currentValue) =>
        previousValue +
        Decimal.mul(
          benefitsShoppingCart[selectedPharmacyUuid][currentValue].quantity,
          Number(benefitsShoppingCart[selectedPharmacyUuid][currentValue].benefit.points),
        ).toNumber(),
      0,
    );
  }

  @Selector()
  public static benefitsForDashboard({ benefitsForDashboard }: AppStateModel): IBenefitsList[] {
    return benefitsForDashboard;
  }

  @Selector()
  public static challengesForDashboard({ challengesForDashboard }: AppStateModel): IChallengesList[] {
    return challengesForDashboard;
  }

  @Selector()
  public static isLorealEmployee({ isLorealEmployee }: AppStateModel): boolean {
    return isLorealEmployee;
  }

  @Selector()
  public static addressAfterLoggingIn({ addressAfterLoggingIn }: AppStateModel): string | null {
    return addressAfterLoggingIn;
  }

  @Selector()
  public static isLoggedToWebinar({ token }: AppStateModel): boolean {
    if (!token) {
      return false;
    }
    const jwtHelperService: JwtHelperService = new JwtHelperService();
    const decodedToken = jwtHelperService.decodeToken(token);
    return decodedToken.role === 'ROLE_CLIENT_LOGIN_WEBINAR';
  }

  @Action(AppStateSetUserData)
  public setUserData(
    { patchState, dispatch, getState }: StateContext<AppStateModel>,
    { payload: { user } }: AppStateSetUserData,
  ): void {
    const { token, language } = getState();

    patchState({
      user: {
        ...user,
        role: this._isLorealClient(token) ? ClientRoleEnum.ROLE_LOREAL : user.role,
      },
      isLorealEmployee: this._isLorealClient(token),
    });

    this._loadPermissionsStrings(user);
    if (this._isLorealClient(token)) {
      this._loadPermissionsStrings(user, ClientRoleEnum.ROLE_LOREAL);
      patchState({
        selectedPharmacyUuid: null,
        selectedPharmacy: null,
      });
      if (user.country.country !== language) {
        dispatch(new AppStateSetLanguage({ language: user.country.country }));
      }
    } else {
      const { selectedPharmacyUuid } = getState();
      let selectedPharmacy: IPharmacyUserDataItem | null = null;
      if (user.pharmacies.length === 1) {
        selectedPharmacy = user.pharmacies[0];
        patchState({
          selectedPharmacyUuid: selectedPharmacy.uuid,
        });
      } else if (selectedPharmacyUuid) {
        selectedPharmacy = user.pharmacies.find((ph) => ph.uuid === selectedPharmacyUuid);
        if (!selectedPharmacy) {
          console.error('Selected pharmacy not found');
          // return;
        }
      } else {
        console.error('Selected pharmacy not found');
        // return;
      }

      if (user.country.country !== language) {
        dispatch(new AppStateSetLanguage({ language: user.country.country }));
      }
    }
  }

  @Action(AppStateGetUserData)
  public getUserData({ patchState, getState }: StateContext<AppStateModel>): Observable<IUser> {
    patchState({ loading: true });
    const { token } = getState();
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    return of(decodedToken?.roles.indexOf(CLIENT_ROLE.ROLE_CLIENT) > -1).pipe(
      concatMap((isClient: boolean) => {
        if (isClient) {
          return this._userService.getMe();
        } else {
          return this._userService.getMeLorealClient();
        }
      }),
      tap((user) => {
        patchState({
          user: {
            ...user,
            role: this._isLorealClient(token) ? ClientRoleEnum.ROLE_LOREAL : user.role,
          },
          isLorealEmployee: this._isLorealClient(token),
        });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(AppStateSetJwtToken)
  public setJwtToken({ patchState }: StateContext<AppStateModel>, { payload: { token } }: AppStateSetJwtToken): void {
    patchState({ token });
  }

  @Action(AppStateFetchPharmaciesNetworks)
  public fetchPharmaciesNetworks({
    patchState,
    getState,
  }: StateContext<AppStateModel>): Observable<{ id: number; name: string }[] | null> {
    const { pharmaciesNetworks } = getState();
    if (pharmaciesNetworks) {
      return of(pharmaciesNetworks);
    }
    patchState({ loading: true });
    return this._dictionariesService.getPharmacyNetworks().pipe(
      tap((pharmaciesNetworks) => {
        patchState({ pharmaciesNetworks });
      }),
      finalize(() => patchState({ loading: false })),
    );
  }

  @Action(AppStateSearchPharmacies)
  public searchPharmacies(
    { patchState }: StateContext<AppStateModel>,
    { payload: { term, country } }: AppStateSearchPharmacies,
  ): Observable<IPharmacy[]> {
    // Reset Pharmacies before change it to list of another country - fix ng-select error
    patchState({ loading: true, pharmacies: [] });
    return this._dictionariesService.searchPharmacies(term, null, country).pipe(
      tap((pharmacies) => {
        patchState({ pharmacies });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(AppStateLogout)
  public logout({ patchState, dispatch, getState }: StateContext<AppStateModel>): Observable<void> {
    const { token } = getState();
    if (!token) {
      patchState({ user: null, token: null, selectedPharmacyUuid: null, selectedPharmacy: null });
      dispatch(new Navigate([RouteSlug.AUTH, RouteSlug.LOGIN]));
      return of(null);
    }

    patchState({ loading: true });
    return of(!this._isLorealClient(token)).pipe(
      concatMap((isClient: boolean) => {
        if (isClient) {
          return this._userService.logout();
        } else {
          return this._userService.logoutLorealClient();
        }
      }),
      tap(() => {
        patchState({ user: null, token: null, loading: false, selectedPharmacy: null, selectedPharmacyUuid: null });
        dispatch(new Navigate([RouteSlug.AUTH, RouteSlug.LOGIN]));
      }),
      catchError((err) => {
        patchState({ user: null, token: null, loading: false, selectedPharmacy: null, selectedPharmacyUuid: null });
        dispatch(new Navigate([RouteSlug.AUTH, RouteSlug.LOGIN]));
        return throwError(() => err);
      }),
    );
  }

  @Action(AppStateClearUserData)
  public clearUserData({ patchState }: StateContext<AppStateModel>): void {
    patchState({ user: null, token: null, selectedPharmacyUuid: null, selectedPharmacy: null });
  }

  @Action(AppStateLoginToEducation)
  public redirect(
    { patchState, getState }: StateContext<AppStateModel>,
    { redirect_to }: AppStateLoginToEducation,
  ): Observable<string> {
    patchState({ loading: true });

    const payload = {
      response_type: 'code',
      scope: 'CLIENT',
      client_id: environment.client_id,
    };
    if (redirect_to) {
      payload['redirect_to'] = redirect_to;
    }
    const observable = getState().isLorealEmployee
      ? this._userService.loginToEducationByLorealClient({
          ...payload,
        })
      : this._userService.loginToEducation({
          ...payload,
        });
    return observable.pipe(
      tap((value) => {
        this._window.open(value, '_self').focus();
        patchState({ loading: false });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(AppStateGetListOfBenefitCategories)
  public getListOfBenefitCategories({
    getState,
    patchState,
  }: StateContext<AppStateModel>): Observable<IBenefitCategoriesDropdown[]> {
    const { benefitCategories } = getState();
    if (benefitCategories) {
      return of(benefitCategories);
    }
    patchState({ loading: true });
    return this._dictionariesService.getAllBenefitCategories().pipe(
      tap((_benefitCategories) => {
        patchState({ benefitCategories: _benefitCategories });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(AppStateGetListOfBenefitMarks)
  public getListOfBenefitMarks({
    getState,
    patchState,
  }: StateContext<AppStateModel>): Observable<IBenefitMarkDropdown[]> {
    const { benefitMarks } = getState();
    if (benefitMarks) {
      return of(benefitMarks);
    }
    patchState({ loading: true });
    return this._dictionariesService.getAllBenefitMarks().pipe(
      tap((_benefitMarks) => {
        patchState({ benefitMarks: _benefitMarks });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(AppStateGetListOfBenefitTypes)
  public getListOfBenefitTypes({
    getState,
    patchState,
  }: StateContext<AppStateModel>): Observable<IBenefitTypeDropdown[]> {
    const { benefitTypes } = getState();
    if (benefitTypes) {
      return of(benefitTypes);
    }
    patchState({ loading: true });
    return this._dictionariesService.getAllBenefitTypes().pipe(
      tap((_benefitTypes) => {
        patchState({ benefitTypes: _benefitTypes });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(AppStateFetchBenefitsForDashboard)
  public fetchBenefitsForDashboard({ getState, patchState }: StateContext<AppStateModel>): Observable<IBenefitsList[]> {
    const { benefitsForDashboard } = getState();
    if (benefitsForDashboard) {
      return of(benefitsForDashboard);
    }
    patchState({ loading: true });
    return this._dictionariesService.getBenefitsForDashboard().pipe(
      tap((_benefitsForDashboard) => {
        patchState({ benefitsForDashboard: _benefitsForDashboard });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(AppStateFetchChallengesForDashboard)
  public fetchChallengesForDashboard(
    { getState, patchState }: StateContext<AppStateModel>,
    { payload: { selectedPharmacyUuid } }: AppStateFetchChallengesForDashboard,
  ): Observable<IChallengesList[]> {
    const { challengesForDashboard } = getState();
    if (challengesForDashboard) {
      return of(challengesForDashboard);
    }
    patchState({ loading: true });
    return this._dictionariesService.getChallengesForDashboard(selectedPharmacyUuid).pipe(
      tap((_challengesForDashboard) => {
        patchState({ challengesForDashboard: _challengesForDashboard });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(AppStateAddOrRemoveBenefitFromShoppingCart)
  public addOrRemoveBenefitFromShoppingCart(
    { patchState, getState }: StateContext<AppStateModel>,
    { payload: { benefit, quantity } }: AppStateAddOrRemoveBenefitFromShoppingCart,
  ): void {
    const { selectedPharmacyUuid } = getState();
    if (!getState().benefitsShoppingCart[selectedPharmacyUuid]) {
      patchState({
        benefitsShoppingCart: {
          ...getState().benefitsShoppingCart,
          [selectedPharmacyUuid]: {},
        },
      });
    }
    const { benefitsShoppingCart } = getState();
    const _quantity = benefitsShoppingCart[selectedPharmacyUuid][benefit.slug]
      ? Decimal.add(benefitsShoppingCart[selectedPharmacyUuid][benefit.slug].quantity, quantity).toNumber()
      : quantity;
    if (_quantity > 0) {
      patchState({
        benefitsShoppingCart: {
          ...benefitsShoppingCart,
          [selectedPharmacyUuid]: {
            ...benefitsShoppingCart[selectedPharmacyUuid],
            [benefit.slug]: {
              benefit,
              quantity: _quantity,
              available: true,
            },
          },
        },
      });
    } else {
      const copyRewardsShoppingCart = { ...benefitsShoppingCart };
      delete copyRewardsShoppingCart[benefit.slug];
      patchState({
        benefitsShoppingCart: copyRewardsShoppingCart,
      });
    }
  }

  @Action(AppStateSetBenefitQuantity)
  public setBenefitQuantity(
    { patchState, getState }: StateContext<AppStateModel>,
    { payload: { benefit, quantity } }: AppStateAddOrRemoveBenefitFromShoppingCart,
  ): void {
    const { benefitsShoppingCart, selectedPharmacyUuid } = getState();
    patchState({
      benefitsShoppingCart: {
        ...benefitsShoppingCart,
        [selectedPharmacyUuid]: {
          ...benefitsShoppingCart[selectedPharmacyUuid],
          [benefit.slug]: {
            benefit,
            quantity,
            available: true,
          },
        },
      },
    });
  }

  @Action(AppStateRemoveWholeBenefitFromShoppingCart)
  public removeWholeRewardFromShoppingCart(
    { patchState, getState }: StateContext<AppStateModel>,
    { payload: { benefit } }: AppStateRemoveWholeBenefitFromShoppingCart,
  ): void {
    const { benefitsShoppingCart, selectedPharmacyUuid } = getState();
    const copyBenefitsShoppingCart = { ...benefitsShoppingCart[selectedPharmacyUuid] };
    delete copyBenefitsShoppingCart[benefit.slug];
    patchState({
      benefitsShoppingCart: {
        ...benefitsShoppingCart,
        [selectedPharmacyUuid]: {
          ...copyBenefitsShoppingCart,
        },
      },
    });
  }

  @Action(AppStateClearShoppingCart)
  public clearShoppingCart({ patchState, getState }: StateContext<AppStateModel>): void {
    const { benefitsShoppingCart, selectedPharmacyUuid } = getState();
    patchState({
      benefitsShoppingCart: {
        ...benefitsShoppingCart,
        [selectedPharmacyUuid]: {},
      },
    });
  }

  @Action(AppStateHideVideo)
  public hideVideo(
    { patchState, dispatch }: StateContext<AppStateModel>,
    { videoName }: AppStateHideVideo,
  ): Observable<unknown> {
    patchState({ loading: true });
    const obs: Observable<unknown> =
      videoName === 'welcome' ? this._userService.hideWelcomeVideo() : this._userService.hideInstructalVideo();
    return obs.pipe(
      concatMap(() => dispatch([new AppStateGetUserData()])),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(AppStateOpenInitialPopups)
  public showScormPopup(): Observable<unknown> {
    return of(true).pipe(
      delay(1000),
      switchMap((_) => {
        return from(this._openModalScormWindow());
      }),
    );
  }

  @Action(AppStateSetAcdAddressAfterLoggingIn)
  public setAcdAddressAfterLoggingIn(
    { patchState }: StateContext<AppStateModel>,
    { payload: { addressAfterLoggingIn } }: AppStateSetAcdAddressAfterLoggingIn,
  ): void {
    patchState({ addressAfterLoggingIn });
  }

  @Action(AppStateSetLanguage)
  public setLanguage(
    { patchState, getState }: StateContext<AppStateModel>,
    { payload: { language } }: AppStateSetLanguage,
  ): void {
    if (language === getState().language) {
      return;
    }
    patchState({
      language,
    });
    location.reload();
  }

  private _loadPermissionsStrings(user: IUser, role?: ClientRoleEnum): void {
    this._ngxPermissionsService.flushPermissions();
    role
      ? this._ngxPermissionsService.loadPermissions([user.role, role])
      : this._ngxPermissionsService.loadPermissions([user.role]);
  }

  private _isLorealClient(token: string): boolean {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    return decodedToken?.roles.indexOf(CLIENT_ROLE.ROLE_LOREAL) > -1;
  }

  private _openModalScormWindow(): Promise<unknown> {
    const modalWindowOptions: NgbModalOptions = {
      centered: true,
      backdrop: true,
      keyboard: true,
      size: 'sm',
      windowClass: 'body',
      modalDialogClass: 'acd-modal-training-dialog',
    };
    const modalRef = this._ngbModal.open(ScormPopupComponent, modalWindowOptions);
    return modalRef.result;
  }
}
