import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppStateSetLanguage } from '../app.state';
import { OnChangeLanguage } from '../tokens/change-language.token';
import { LanguageEnum } from '@acd-loreal/data';

@Injectable({ providedIn: 'root' })
export class ChangeLanguageService implements OnChangeLanguage {
  constructor(private readonly _store: Store) {}
  public onChangeLanguage(language: LanguageEnum): void {
    this._store.dispatch(new AppStateSetLanguage({ language }));
  }
}
