import { isPlatformServer } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { skip } from 'rxjs/operators';
import { NetworkService } from './services/network.service';
import { OneTrustService } from './services/one-trust.service';
import { GoogleTagManagerService } from './services/google-tag-manager.service';
import { Angulartics2GoogleAnalytics, Angulartics2GoogleTagManager } from 'angulartics2';

@Component({
  selector: 'acd-root',
  template: ` <router-outlet /> `,
})
@UntilDestroy({ checkProperties: true })
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private readonly _ngbModal: NgbModal,
    private readonly _router: Router,
    @Inject(PLATFORM_ID)
    private readonly _platformId: unknown,
    private readonly _networkService: NetworkService,
    private readonly _toastrService: ToastrService,
    private readonly _elementRef: ElementRef,
    private readonly _oneTrustService: OneTrustService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly _angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private readonly _angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
  ) {}

  public ngOnInit(): void {
    this._setRouterSubscription();
    this._setOnlineOfflineChangesSubscription();
    this._elementRef.nativeElement.removeAttribute('ng-version');
  }

  public ngAfterViewInit(): void {
    this._oneTrustService.addScriptToDom().then(() => {
      this._googleTagManagerService.addScriptToDom().then(() => {
        this._angulartics2GoogleAnalytics.startTracking();
        this._angulartics2GoogleTagManager.startTracking();
      });
    });
  }

  private _setOnlineOfflineChangesSubscription(): void {
    if (isPlatformServer(this._platformId)) {
      return;
    }
    this._networkService.onlineChanges$.pipe(untilDestroyed(this), skip(1)).subscribe((online) => {
      if (online) {
        this._toastrService.info('Odzyskano połączenie internetowe.');
      } else {
        this._toastrService.warning('Utracono połączenie internetowe.');
      }
    });
  }

  private _setRouterSubscription(): void {
    if (isPlatformServer(this._platformId)) {
      return;
    }
    this._router.events.subscribe((routeEvent) => {
      if (routeEvent instanceof NavigationEnd) {
        this._ngbModal.dismissAll();
      }
    });
  }
}
