import * as XSS from 'xss';

export function getSafeValue(value: string): string {
  const _hasContentInHTMLTags = hasContentInHTMLTags(value);
  if (!_hasContentInHTMLTags) {
    return '';
  }
  const whiteList = XSS.getDefaultWhiteList();
  for (const key in whiteList) {
    whiteList[key].push('class');
  }
  whiteList['marquee'] = ['direction'];
  whiteList.span.push('style');
  whiteList.video.push('muted');
  whiteList.img.push('data-youtube');
  whiteList.div.push('style', 'id');
  whiteList.i = ['class'];
  whiteList.a.push('data-friser', 'data-hairstore');
  const filterXSS = new XSS.FilterXSS({
    whiteList,
    css: {
      whiteList: {
        color: true,
        'background-color': true,
        'font-size': true,
        'font-family': true,
        'line-height': true,
        display: true,
      },
    },
  });
  return filterXSS.process(value);
}

function hasContentInHTMLTags(html): boolean {
  // Regular expression to match HTML tags and their content
  const regex = /<([^>]+)>([^<]*)<\/\1>/;

  // Use the regex to extract the tag name and content
  const matches = html.match(regex);

  if (!matches) {
    // If no match found, the tag is either not well-formed or empty
    return false;
  }

  // Get the content from the second capturing group
  const content = matches[2].trim();

  // Return true if the content is not empty
  return content !== '';
}
