import { FormControl, ValidationErrors } from '@angular/forms';

export class PasswordValidator {
  public static special(control: FormControl<string>): ValidationErrors {
    const hasSpecial = /[!@#$%^&*()_+\-=\\[\]{};'`:"\\|,.<>\\/?]/.test(control.value);
    if (!hasSpecial) {
      return {
        hasSpecial: true,
      };
    }
    return null;
  }

  public static number(control: FormControl<string>): ValidationErrors {
    const hasNumber = /\d/.test(control.value);
    if (!hasNumber) {
      return {
        hasNumber: true,
      };
    }
    return null;
  }

  public static upper(control: FormControl<string>): ValidationErrors {
    const hasUpper = /[A-Z]/.test(control.value);
    if (!hasUpper) {
      return {
        hasUpper: true,
      };
    }
    return null;
  }

  public static lower(control: FormControl<string>): ValidationErrors {
    const hasLower = /[a-z]/.test(control.value);
    if (!hasLower) {
      return {
        hasLower: true,
      };
    }
    return null;
  }
}
