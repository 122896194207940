import { FileItem, FileUploader } from 'ng2-file-upload';
import { IFileInputResult } from '../../models';

export async function filesFromUploader(uploaderFileProject: FileUploader): Promise<IFileInputResult[]> {
  if (uploaderFileProject.queue.length === 0) {
    return new Promise((resolve) => {
      resolve([]);
    });
  }

  const _handleAfterAddingFile = (item: FileItem): Promise<IFileInputResult> => {
    const reader = new FileReader();
    reader.readAsDataURL(item._file);
    return new Promise((resolve) => {
      reader.onload = (event) => {
        const prefix = event.target.result.toString().split(',')[0].toLowerCase();
        resolve({
          name: item._file.name,
          content: event.target.result.toString().split(',')[1],
          imageSrc: event.target.result,
          isImageFile: prefix.includes('png') || prefix.includes('jpg') || prefix.includes('jpeg'),
          fileItem: item,
        });
      };
    });
  };

  const files: IFileInputResult[] = [];
  for (let i = 0; i < uploaderFileProject.queue.length; i++) {
    const fileItem = uploaderFileProject.queue[i];
    const file = await _handleAfterAddingFile(fileItem);
    files.push(file);
  }

  return new Promise((resolve) => {
    resolve(files);
  });
}
