import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { fromIntersectionObserver, IntersectionStatus } from './from-intersection-observer';

@Directive({ selector: '[appIntersectionObserver]', standalone: true })
@UntilDestroy()
export class IntersectionObserverDirective implements AfterViewInit {
  @Output()
  public readonly visibilityChange = new EventEmitter<IntersectionStatus>();

  constructor(
    private readonly _elementRef: ElementRef,
    @Inject(PLATFORM_ID)
    protected readonly _platformId: unknown,
  ) {}

  private _intersectionDebounce = 0;

  get intersectionDebounce(): number {
    return this._intersectionDebounce;
  }

  @Input()
  set intersectionDebounce(value: number) {
    this._intersectionDebounce = value;
  }

  private _intersectionRootMargin = '0px';

  get intersectionRootMargin(): string {
    return this._intersectionRootMargin;
  }

  @Input()
  set intersectionRootMargin(value: string) {
    this._intersectionRootMargin = value;
  }

  private _intersectionRoot: HTMLElement;

  get intersectionRoot(): HTMLElement {
    return this._intersectionRoot;
  }

  @Input()
  set intersectionRoot(value: HTMLElement) {
    this._intersectionRoot = value;
  }

  private _intersectionThreshold: number | number[];

  get intersectionThreshold(): number | number[] {
    return this._intersectionThreshold;
  }

  @Input()
  set intersectionThreshold(value: number | number[]) {
    this._intersectionThreshold = value;
  }

  public ngAfterViewInit(): void {
    if (isPlatformServer(this._platformId)) {
      return;
    }
    const element = this._elementRef.nativeElement;
    const config: IntersectionObserverInit = {
      root: this._intersectionRoot,
      rootMargin: this._intersectionRootMargin,
      threshold: this._intersectionThreshold,
    };

    fromIntersectionObserver(element, config, this._intersectionDebounce)
      .pipe(untilDestroyed(this))
      .subscribe((status) => {
        this.visibilityChange.emit(status);
      });
  }
}
