import {
  ContextMenuModule,
  LanguageEnum,
  SERVICES_API,
  SERVICES_CUSTOMER_TOKEN,
  TRANSLATE_VALIDATION_MESSAGES,
  WINDOW,
} from '@acd-loreal/data';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEe from '@angular/common/locales/ee';
import localeLt from '@angular/common/locales/lt';
import localeLv from '@angular/common/locales/lv';
import localePl from '@angular/common/locales/pl';
import { APP_ID, APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NbDialogModule, NbOverlayModule, NbPopoverModule, NbSidebarModule, NbThemeModule } from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { Navigate, NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { Angulartics2Module } from 'angulartics2';
import { LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { RECAPTCHA_LOADER_OPTIONS, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { provideNgxMask } from 'ngx-mask';
import { NgxPermissionsModule } from 'ngx-permissions';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { ToastrModule } from 'ngx-toastr';
import { EMPTY, firstValueFrom, mergeMap } from 'rxjs';
import { getDocument } from 'ssr-window';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import {
  APP_ACD_ADDRESS_AFTER_LOGGING_IN,
  APP_BENEFITS_SHOPPING_CART,
  APP_COOKIES_KEY,
  APP_JWT_TOKEN,
  APP_PHARMACY_UUID,
  APP_TOKEN_EXPIRED_AT,
  LANGUAGE_KEY,
  MENOPAUSE_ANSWER,
  SCIENCE_PHARMA_REGISTRATION_TYPE_AFTER_ENTERING_PAGE,
  SUN_COMPETITION_ACTUAL_QUESTION,
  SUN_COMPETITION_ANS_1,
  SUN_COMPETITION_ANS_2,
  SUN_COMPETITION_ANS_3,
  SUN_COMPETITION_ANS_4,
  SUN_COMPETITION_ANS_5,
  SUN_COMPETITION_ANS_6,
  SUN_COMPETITION_ANS_7,
  SUN_COMPETITION_ANS_8,
  SUN_COMPETITION_SESSION_ID,
  SUN_COMPETITION_SUN_STATUS,
} from './app.consts';
import { APP_ROUTES } from './app.routing';
import {
  AppState,
  AppStateClearUserData,
  AppStateLogout,
  AppStateSetAcdAddressAfterLoggingIn,
  AppStateSetJwtToken,
  AppStateSetUserData,
} from './app.state';
import { SsrGuard } from './guards/ssr.guard';
import { isJwtTokenExpired } from './helpers/is-jwt-token-expired';
import { TranslateValidationMessages } from './helpers/translate-validation-messages';
import { I18nModule } from './i18n.module';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { LanguageInterceptor } from './interceptors/language.interceptor';
import { CLIENT_ROLE, IUser, RouteSlug } from './models';
import { ChangeLanguageService } from './services/change-language.service';
import { SERVICES_API_PREFIX } from './services/tokens';
import { UserService } from './services/user.service';
import { ON_CHANGE_LANGUAGE } from './tokens/change-language.token';

const registerLocale = (language: LanguageEnum) => {
  switch (language) {
    case LanguageEnum.LV:
      registerLocaleData(localeLv);
      break;
    case LanguageEnum.LT:
      registerLocaleData(localeLt);
      break;
    case LanguageEnum.ET:
      registerLocaleData(localeEe);
      break;
    default:
      registerLocaleData(localePl);
  }
};

// TO REMOVE AFTER COMPETITION ENDED
const LOCAL_STORAGE_STATE_VALUES_SUN_COMP = [
  SUN_COMPETITION_ACTUAL_QUESTION,
  SUN_COMPETITION_SESSION_ID,
  SUN_COMPETITION_ANS_1,
  SUN_COMPETITION_ANS_2,
  SUN_COMPETITION_ANS_3,
  SUN_COMPETITION_ANS_4,
  SUN_COMPETITION_ANS_5,
  SUN_COMPETITION_ANS_6,
  SUN_COMPETITION_ANS_7,
  SUN_COMPETITION_ANS_8,
  SUN_COMPETITION_SUN_STATUS,
];

const LOCAL_STORAGE_STATE_VALUES = [
  APP_JWT_TOKEN,
  APP_TOKEN_EXPIRED_AT,
  LANGUAGE_KEY,
  APP_COOKIES_KEY,
  APP_BENEFITS_SHOPPING_CART,
  APP_PHARMACY_UUID,
  APP_ACD_ADDRESS_AFTER_LOGGING_IN,
  MENOPAUSE_ANSWER,
  SCIENCE_PHARMA_REGISTRATION_TYPE_AFTER_ENTERING_PAGE,
  ...LOCAL_STORAGE_STATE_VALUES_SUN_COMP,
];

const NGXS_MODULES = [
  NgxsModule.forRoot([AppState], {
    developmentMode: !environment.production,
    selectorOptions: {
      suppressErrors: false,
      injectContainerState: false,
    },
    compatibility: {
      strictContentSecurityPolicy: true,
    },
  }),
  NgxsRouterPluginModule.forRoot(),
  NgxsFormPluginModule.forRoot(),
  NgxsLoggerPluginModule.forRoot({
    logger: console,
    collapsed: false,
    disabled: environment.production,
  }),
  NgxsStoragePluginModule.forRoot({
    storage: StorageOption.LocalStorage,
    key: [...LOCAL_STORAGE_STATE_VALUES],
  }),
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    NbThemeModule.forRoot(),
    NbDialogModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbOverlayModule.forRoot(),
    NbPopoverModule,
    NgSelectModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HammerModule,
    RecaptchaV3Module,
    NgxPermissionsModule.forRoot(),
    Angulartics2Module.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
    }),
    QuicklinkModule,
    RouterModule.forRoot(APP_ROUTES, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: QuicklinkStrategy,
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
    }),
    ContextMenuModule.forRoot(),
    ...NGXS_MODULES,
  ],
  providers: [
    provideNgxMask(),
    { provide: APP_ID, useValue: 'serverApp' },
    SsrGuard,
    JwtHelperService,
    I18nModule.setLocale(),
    I18nModule.setLocaleId(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: SERVICES_API_PREFIX,
      useFactory: (store: Store) => () => {
        const isLoreal = store.selectSnapshot(AppState.isLorealEmployee);
        return isLoreal ? 'loreal-client' : 'platform';
      },
      deps: [Store],
    },
    { provide: TRANSLATE_VALIDATION_MESSAGES, useClass: TranslateValidationMessages },
    { provide: DOCUMENT, useValue: getDocument() },
    { provide: SERVICES_API, useValue: environment.api },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    {
      provide: RECAPTCHA_LOADER_OPTIONS,
      useFactory: (locale: string) => ({
        onBeforeLoad(url: URL) {
          url.searchParams.set('hl', locale);
          return { url };
        },
      }),
      deps: [LOCALE_ID],
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaV3SiteKey,
    },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    {
      provide: SERVICES_CUSTOMER_TOKEN,
      useFactory: (store: Store) => () => store.selectSnapshot(AppState.token),
      deps: [Store],
    },
    {
      provide: ON_CHANGE_LANGUAGE,
      useClass: ChangeLanguageService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (window: Window, userService: UserService, store: Store) => async () => {
        const language = store.selectSnapshot(AppState.language);
        registerLocale(language);
        const hash = window.location.href.split('#');
        let predictReturnUrl =
          window.location.pathname && window.location.pathname.indexOf('/auth') === -1 ? window.location.pathname : '';
        if (window.location.search) {
          predictReturnUrl = `${predictReturnUrl}${window.location.search}`;
        }
        if (hash.length > 1) {
          predictReturnUrl = `${predictReturnUrl}#${hash[1]}`;
        }
        const { token } = store.selectSnapshot(AppState);
        if (!token || isJwtTokenExpired(token)) {
          if (predictReturnUrl) {
            store.dispatch(new AppStateSetAcdAddressAfterLoggingIn({ addressAfterLoggingIn: predictReturnUrl }));
          }
          store.dispatch(new AppStateClearUserData());
          return EMPTY;
        }

        let decodedToken = null;
        try {
          const helper = new JwtHelperService();
          decodedToken = helper.decodeToken(token);
        } catch (error) {
          return EMPTY;
        }

        return firstValueFrom(
          decodedToken?.roles.indexOf(CLIENT_ROLE.ROLE_CLIENT) > -1
            ? userService.getMe()
            : userService.getMeLorealClient(),
        )
          .then((user: IUser) => {
            store.dispatch([
              new AppStateSetUserData({ user }),
              new AppStateSetAcdAddressAfterLoggingIn({ addressAfterLoggingIn: predictReturnUrl }),
            ]);
          })
          .catch((reason) => {
            if (reason?.status === 403) {
              store.dispatch([new Navigate([RouteSlug.AUTH, RouteSlug.LOGIN])]).pipe(
                mergeMap(() => {
                  return store.dispatch([
                    new AppStateSetJwtToken({ token: null }),
                    new AppStateLogout(),
                    new AppStateSetAcdAddressAfterLoggingIn({ addressAfterLoggingIn: predictReturnUrl }),
                  ]);
                }),
              );
            }
          });
      },
      deps: [WINDOW, UserService, Store],
      multi: true,
    },
    UserService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
