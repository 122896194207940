import { Routes } from '@angular/router';
import { RouteSlug } from './models';
import { SsrGuard } from './guards/ssr.guard';
import { AuthGuard } from './guards/auth.guard';
import { PAGE_TITLE_PREFIX } from './app.consts';

export const APP_ROUTES: Routes = [
  {
    path: RouteSlug.SSR_REDIRECT,
    loadComponent: () =>
      import('./ssr-redirect/ssr-redirect.component').then(({ SsrRedirectComponent }) => SsrRedirectComponent),
  },
  {
    path: `${RouteSlug.LIVE_CHAT}/:slug`,
    canActivate: [SsrGuard, AuthGuard],
    loadComponent: () => import('./pages/chat/chat.component').then(({ ChatComponent }) => ChatComponent),
    title: `${PAGE_TITLE_PREFIX}Czat`,
  },
  {
    path: '',
    loadChildren: () => import('./pages/core/core.routing').then(({ ROUTES }) => ROUTES),
  },
];
