import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AppStateLogout } from '../app.state';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly _toastrService: ToastrService,
    private readonly _store: Store,
  ) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const statusTimeout = [504, 408, 0];
        if (statusTimeout.includes(error.status)) {
          this._toastrService.warning('Sieć internetowa, z której korzystasz jest zbyt słaba.');
        } else if (error.status === 403) {
          this._store.dispatch(new AppStateLogout());
        }
        // else if (error.status === 400 && error?.error?.message) {
        //   this._toastrService.warning(error?.error?.message);
        // }
        return throwError(() => error);
      }),
    );
  }
}
