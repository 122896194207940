import { Pipe, PipeTransform } from '@angular/core';
import { formatBytes } from '@acd-loreal/data';

@Pipe({
  name: 'formatBytes',
  standalone: true,
})
export class FormatBytesPipe implements PipeTransform {
  public transform(bytes: number, decimals = 2): string {
    return formatBytes(bytes, decimals);
  }
}
