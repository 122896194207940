import { FormControl } from '@angular/forms';

export function matchOtherValidator(otherControlName: string, isMatch = true) {
  let thisControl: FormControl<string>;
  let otherControl: FormControl<string>;

  return function matchOtherValidate(control: FormControl<string>) {
    if (!control.parent) {
      return null;
    }

    // Initializing the validator.
    if (!thisControl) {
      thisControl = control;
      otherControl = control.parent.get(otherControlName) as FormControl<string>;
      if (!otherControl) {
        throw new Error('matchOtherValidator(): other control is not found in parent group');
      }
      otherControl.valueChanges.subscribe(() => {
        thisControl.updateValueAndValidity();
      });
    }

    if (!otherControl) {
      return null;
    }

    if (otherControl.value !== thisControl.value && isMatch) {
      return {
        matchOther: true,
      };
    } else if (otherControl.value === thisControl.value && !isMatch) {
      return {
        matchOther: true,
      };
    }

    return null;
  };
}
