export enum ClientPositionEnum {
  MAGISTER = 'magister',
  TECHNIK = 'technik',
  DERMO_CONSULTANT = 'dermokonsultant',

  // Baltics
  PHARMACIST = 'pharmacist',
  PHARMACIST_CONSULTANT = 'pharmacist_consultant',
  // these below probably are not used in the app
  PHARMACEUTICAL_REPRESENTATIVE = 'reprezentant_farmaceutyczny',
  MEDICAL_REPRESENTATIVE = 'reprezentant_medyczny',
  DERMO_ADVISOR = 'dermoadvisor_acd',
  ACD_EMPLOYEE = 'pracownik_acd',
}
