import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@Directive({
  selector: '[appShowPassword]',
  standalone: true,
})
@UntilDestroy()
export class InputShowPasswordDirective implements OnInit, OnDestroy {
  @Input({ alias: 'appShowPassword', required: true })
  public appShowPassword: string;

  @Input({ required: true })
  public iAlt: string;

  @Input({ required: true })
  public iWidth: string;

  @Input({ required: true })
  public iHeight: string;

  constructor(
    private readonly _renderer2: Renderer2,
    private readonly _elementRef: ElementRef,
    @Inject(DOCUMENT)
    private readonly _document: Document,
  ) {}

  private _listenFunc: any;
  private _inputType: 'password' | 'text' = 'password';

  public ngOnInit(): void {
    this._renderer2.setStyle(this._elementRef.nativeElement, 'posiiton', 'relative');

    const input = this._elementRef.nativeElement.children[0];

    const img = this._renderer2.createElement('img');

    this._renderer2.setStyle(img, 'display', 'block');
    this._renderer2.setStyle(img, 'width', `${this.iWidth}px`);
    this._renderer2.setStyle(img, 'height', `${Number(this.iHeight)}px`);
    this._renderer2.setStyle(img, 'position', 'absolute');
    this._renderer2.setStyle(img, 'bottom', `${(50 - Number(this.iHeight)) / 2}px`);
    this._renderer2.setStyle(img, 'right', '20px');
    this._renderer2.setStyle(img, 'zIndex', '100');
    this._renderer2.setStyle(img, 'cursor', 'pointer');
    this._renderer2.setAttribute(img, 'src', this.appShowPassword);
    this._renderer2.setAttribute(img, 'alt', this.iAlt);

    this._renderer2.appendChild(this._elementRef.nativeElement, img);
    this._renderer2.setStyle(input, 'paddingRight', '50px');

    this._listenFunc = this._renderer2.listen(img, 'click', (_) => {
      if (this._inputType === 'password') {
        this._renderer2.setAttribute(input, 'type', 'text');
        this._inputType = 'text';
      } else {
        this._renderer2.setAttribute(input, 'type', 'password');
        this._inputType = 'password';
      }
    });
  }

  public ngOnDestroy(): void {
    this._listenFunc();
  }
}
