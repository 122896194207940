import { OnTranslateValidationMessages } from '@acd-loreal/data';

export class TranslateValidationMessages implements OnTranslateValidationMessages {
  public onTranslateValidationMessages(validatorName: string, validatorValue?: any): string {
    const passwordsMessage = $localize`:@@validation-message.invalid-password:Hasło powinno zawierać co najmniej 8 znaków, w tym małą i dużą literę, znak specjalny i cyfrę.`;
    const config: {
      [key: string]: string;
    } = {
      npwzPharmacist: $localize`:@@validation-message.npwz-pharmacist:Podaj poprawny numer PWZF`,
      validatePhoneNumber: $localize`:@@validation-message.phone-number:Podany numer telefonu jest niepoprawny.`,
      serialIsNotValid: $localize`:@@validation-message.serial-is-not-valid:Podany numer seryjny/fabryczny jest niepoprawny.`,
      cardNumber: $localize`:@@validation-message.card-number:Podaj poprawny numer karty.`,
      mask: $localize`:@@validation-message.mask:Podaj wartość w poprawnym formacie.`,
      nip: $localize`:@@validation-message.nip:Podaj poprawny numer NIP.`,
      pesel: $localize`:@@validation-message.pesel:Podaj poprawny numer pesel.`,
      required: $localize`:@@validation-message.required:To pole jest obowiązkowe.`,
      email: $localize`:@@validation-message.email:Niepoprawny format adresu e-mail.`,
      maxlength: $localize`:@@validation-message.max-length:Maksymalna długość pola to ${validatorValue['requiredLength']}.`,
      minlength: $localize`:@@validation-message.min-length:Minimalna długość pola to ${validatorValue['requiredLength']}.`,
      matchOther: $localize`:@@validation-message.match-other:Podane hasła muszą być identyczne.`,
      hasUpper: passwordsMessage,
      hasSpecial: passwordsMessage,
      hasNumber: passwordsMessage,
      hasLower: passwordsMessage,
      ngbDate: $localize`:@@validation-message.date:Podaj poprawną datę.`,
    };
    return config[validatorName];
  }
}
