export function validatorErrorMessage(validatorName: string, validatorValue?: unknown): string {
  const passwordsMessage =
    'Hasło powinno zawierać co najmniej 8 znaków, w tym małą i dużą literę, znak specjalny i cyfrę.';
  const config = {
    npwzPharmacist: 'Podaj poprawny numer PWZF.',
    validatePhoneNumber: 'Podany numer telefonu jest niepoprawny.',
    serialIsNotValid: 'Podany numer seryjny/fabryczny jest niepoprawny.',
    serialExist: 'Podany numer seryjny/fabryczny już istnieje.',
    productNotAvailable: 'Produkt niedostępny w tej promocji.',
    cardNumber: 'Podaj poprawny numer karty.',
    mask: 'Podaj wartość w poprawnym formacie.',
    nip: 'Podaj poprawny numer NIP.',
    pesel: 'Podaj poprawny numer pesel.',
    email: 'Niepoprawny format adresu e-mail.',
    required: 'To pole jest obowiązkowe.',
    maxlength: `Maksymalna długość pola to ${validatorValue['requiredLength']}.`,
    minlength: `Minimalna długość pola to ${validatorValue['requiredLength']}.`,
    matchOther: `Podane hasła muszą być identyczne.`,
    hasUpper: passwordsMessage,
    hasSpecial: passwordsMessage,
    hasNumber: passwordsMessage,
    hasLower: passwordsMessage,
    min: `Minimalna wartość to ${validatorValue['min']}`,
    max: `Maksymalna wartość to ${validatorValue['max']}`,
  };
  return config[validatorName];
}
