import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { WINDOW } from '@acd-loreal/data';
import { IGTMTag } from '../models/gtm-tag';

declare const gtag;
@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  public readonly ID_GTM_SCRIPT = 'acd-gtm-script';
  public readonly ID_GTM_IFRAME = 'acd-gtm-iframe';

  private readonly _renderer2: Renderer2;

  constructor(
    @Inject(WINDOW)
    public readonly _window: Window,
    @Inject(DOCUMENT)
    private readonly _document: Document,
    private readonly _rendererFactory: RendererFactory2,
  ) {
    this._renderer2 = this._rendererFactory.createRenderer(null, null);
  }

  public getDataLayer(): any {
    this._createDataLayer();
    return this._window['dataLayer'];
  }

  public addScriptToDom(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      if (!environment.gtm) {
        reject('Lack of gtm key.');
        return;
      }
      if (this._scriptExists()) {
        return resolve;
      }
      if (!environment.production) {
        return resolve;
      }

      this._createDataLayer();
      this._pushOnDataLayer({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      const gtmScript = this._renderer2.createElement('script');
      gtmScript.id = this.ID_GTM_SCRIPT;
      gtmScript.defer = true;
      gtmScript.async = true;
      gtmScript.onload = resolve;
      gtmScript.onerror = reject;
      gtmScript.src = `//www.googletagmanager.com/gtm.js?id=${environment.gtm}`;
      this._document.head.insertBefore(gtmScript, this._document.head.firstChild);

      const ifrm = this._renderer2.createElement('iframe');
      ifrm.setAttribute('src', `//www.googletagmanager.com/ns.html?id=${environment.gtm}`);
      ifrm.style.width = '0';
      ifrm.style.height = '0';
      ifrm.style.display = 'none';
      ifrm.style.visibility = 'hidden';

      const noscript = this._renderer2.createElement('noscript');
      noscript.id = this.ID_GTM_IFRAME;
      noscript.appendChild(ifrm);

      this._document.body.insertBefore(noscript, this._document.body.firstChild);
    });
  }

  public pushTag(obj: IGTMTag): void {
    this._pushOnDataLayer(obj);
  }

  private _scriptExists(): boolean {
    const script = this._document.getElementById(this.ID_GTM_SCRIPT);
    return !!script;
  }

  private _createDataLayer(): void {
    if (!this._window['dataLayer']) this._window['dataLayer'] = this._window['dataLayer'] || [];
  }

  private _pushOnDataLayer(obj): void {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }
}
