import { Pipe, PipeTransform } from '@angular/core';
import { ClientRoleEnum } from '../enums';

@Pipe({
  name: 'clientRole',
  standalone: true,
})
export class ClientRolePipe implements PipeTransform {
  public transform(role: ClientRoleEnum): string {
    switch (role) {
      case ClientRoleEnum.ROLE_EMPLOYEE:
        return 'Pracownik';
      case ClientRoleEnum.ROLE_OWNER:
        return 'Właściciel/Kierownik';
      default:
        return 'Not recognized role.';
    }
  }
}
