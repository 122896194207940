import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

export function setFormErrors(errorsObj: any, form: UntypedFormGroup | UntypedFormArray): void {
  if (typeof errorsObj === 'string') {
    return;
  }

  if (Array.isArray(errorsObj)) {
    for (let i = 0; i < errorsObj.length; i++) {
      setFormErrors(errorsObj[i]['children'], form?.controls[i]);
    }
    return;
  }

  for (const key in errorsObj) {
    if (!Object.prototype.hasOwnProperty.call(errorsObj, key)) {
      continue;
    }

    if (typeof errorsObj[key] === 'object' && errorsObj[key] !== null && !Array.isArray(errorsObj[key])) {
      const errors = {};
      for (const err in errorsObj[key]) {
        if (Object.prototype.hasOwnProperty.call(errorsObj[key], err)) {
          errors[err] = errorsObj[key][err];
        }
      }
      if (Object.keys(errors).length > 0 && errors['errors'] && form.get(key)) {
        form.get(key).setErrors({ backend: { errors: errors['errors'][0] } });
      }
    } else if (form.get(key)) {
      form.get(key).setErrors({ backend: [errorsObj[key]] });
    }
  }
}
