<div class="acd-scorm-popup">
  <div class="acd-scorm-popup__clo" (click)="onClose(); $event.prevenDefault()">
    <img alt="close" [priority]="false" [width]="27" [height]="27" [ngSrc]="'assets/images/close_thin.svg'" />
  </div>

  <!-- <img class="acd-scorm-popup__img" src="assets/images/scorm-popup/logo.png" /> -->

  <div class="acd-scorm-popup__con">
    <div class="acd-scorm-popup__con__wr">
      <!-- <p class="acd-scorm-popup__con__wr__title">Zmieniaj życie Twoich pacjentów</p> -->
      <a
        (click)="onClose()"
        [routerLink]="['/', routeSlug.ACCOUNT, routeSlug.INTERACTIVE_TRAINING]"
        target="_self"
        class="acd-scorm-popup__link"
      >
        <img class="acd-scorm-popup__con__wr__img d-none d-md-block" src="assets/images/scorm-popup/skin-stream.jpg" />
        <img
          class="acd-scorm-popup__con__wr__img d-block d-md-none"
          src="assets/images/scorm-popup/skin-stream-mobile.jpg"
        />
      </a>
    </div>
  </div>

  <!-- <div class="row acd-scorm-popup__bot">
    <div class="col-12 acd-scorm-popup__bot__wr">
      <a
        (click)="onClose()"
        [routerLink]="['/', routeSlug.ACCOUNT, routeSlug.INTERACTIVE_TRAINING]"
        target="_self"
        class="acd-btn--dark acd-btn--md d-inline-block acd-scorm-popup__bot__wr__btn"
        >Jak to zrobić
      </a>
    </div>
  </div> -->
</div>
