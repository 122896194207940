import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'polishPlural',
  standalone: true,
})
export class PolishPluralPipe implements PipeTransform {
  public transform(
    value: number,
    singularNominativ: string = 'wynik',
    pluralNominativ: string = 'wyniki',
    pluralGenitive: string = 'wyników',
    withoutValue: boolean = false,
  ): string {
    if (value === 1) {
      return `${withoutValue ? '' : value} ${singularNominativ}`;
    } else if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20)) {
      return `${withoutValue ? '' : value} ${pluralNominativ}`;
    } else {
      return `${withoutValue ? '' : value} ${pluralGenitive}`;
    }
  }
}
